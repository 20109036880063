<template>
  <section class="page-container">
    <v-img :min-height="minHeightProp" :src="require('@/assets/world-1999x921.jpeg')" class="white--text" gradient="to top, rgba(0,4,13, 0.6), rgba(0,0,0,.9)">
      <v-container class="fill-height px-4 py-12">
        <v-responsive class="d-flex align-center mx-auto mt-4" height="100%" width="100%">
          <base-heading weight="regular" title="WELCOME TO PROINSIGHT" />
          <div :class="`font-weight-light mt-2 ${isSmallScreenProp ? 'text-h6 ' : 'text-h5 '}`">ProInsight™ connects and trains relationship-based real estate and financial services professionals to better serve more of their ideal clients.</div>
          <v-timeline light class="custom-timeline mt-14" align-top :dense="isSmallScreenProp">
            <v-timeline-item :large="isSmallScreenProp" v-for="(keypoint, i) in keypoints" :key="i" :color="keypoint.color" :icon="keypoint.icon" fill-dot>
              <div :class="['keypoint-card', { 'active-card': activeCard === i }]" @mousedown="activateCard(i)" @mouseup="deactivateCard()" @mouseleave="deactivateCard()" @touchstart="activateCard(i)" @touchend="deactivateCard()">
                <a :href="keypoint.url" class="link" style="width: 100%; display: flex; align-items: center">
                  <div style="flex-grow: 1">
                    <div :style="{ color: keypoint.color }" :class="`font-weight-bold mt-2 ${isSmallScreenProp ? 'subtitle-2' : 'subtitle-1'}`">{{ keypoint.title }}</div>
                    <div :class="`font-weight-light pt-1 text-${keypoint.color} ${isSmallScreenProp ? 'body-2' : 'body-1'}`">{{ keypoint.message }}</div>
                  </div>
                  <v-btn x-large icon :color="keypoint.color"><v-icon>mdi-chevron-right</v-icon></v-btn>
                </a>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
export default {
  name: 'Welcome',
  data() {
    return {
      activeCard: null,
      keypoints: [
        {
          title: '1. Become PI Certified',
          numericicon: 'mdi-numeric-1',
          message: 'Professional Insight Certification™',
          icon: 'mdi-account-school',
          color: 'success',
          url: 'https://www.proinsight.com'
        },
        {
          title: '2. Create a team',
          numericicon: 'mdi-numeric-2',
          message: 'Create a Wealth Advisory Team.',
          icon: 'mdi-account-group',
          color: 'amber',
          url: 'https://www.proinsight.com/learning-center-2/'
        },
        {
          title: '3. Connect',
          numericicon: 'mdi-numeric-3',
          message: 'Connect to other like-kind Wealth Advisory Teams on a national, digital referral network.',
          icon: 'mdi-account-switch',
          color: 'primary',
          url: 'https://www.proinsight.app'
        }
      ]
    }
  },
  computed: {
    minHeightProp() {
      const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'
      return `calc(${height} - ${this.$vuetify.application.top}px)`
    },
    isSmallScreenProp() {
      return this.$vuetify.breakpoint.xsOnly
    }
  },
  mounted() {
    // Listen to the popstate event when navigating back from an external page - this avoids flickering
    window.addEventListener('popstate', () => {})
  },
  methods: {
    activateCard(selectedCard) {
      this.activeCard = selectedCard
    },
    deactivateCard() {
      this.activeCard = null
    }
  }
}
</script>

<style>
.theme--light.v-timeline:before {
  background: white;
  opacity: 0.7;
  width: 2px;
}

.keypoint-card {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.4);
  margin-top: -5px;
  margin-bottom: 20px;
  margin-right: 10px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 10px;
  transition: transform 0.3s;
}

.active-card {
  transform: scale(0.97);
}

custom-timeline {
  border-radius: 6px;
  padding: 1px;
  margin-top: 4px;
}

.text-primary {
  color: #66c0ff;
}

.text-success {
  color: #4caf50;
}

.text-amber {
  color: #ffc107;
}

.link {
  color: inherit !important;
  text-decoration: none;
}
</style>
